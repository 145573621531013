<template>
    <div class="page-wrap">
      <div class="page-main flex-column">
       
        <div class="main"><router-view></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>

export default {
  name: 'Entry',
  components: {  },
  data() {
    return {
    
     
     
     
    }
  },
  created(){
    
  },
  methods:{
    
    
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
